import { css } from "@emotion/react";

export const defaultFontFiles = ({ baseUrl = "./" } = {}) => {
  const localInstanceUrl = baseUrl.endsWith("/") ? baseUrl : `${baseUrl}/`;
  const basePath = `${localInstanceUrl}app/fonts/Montserrat`;

  return css`
      /* lato-regular - latin */

      @font-face {
        font-family: Montserrat;
        src: local("Montserrat Regular"), local("Montserrat-Regular"),
        url("${basePath}/Montserrat/Montserrat-Regular.woff2") format("woff2");
        font-weight: normal;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: Montserrat;
        src: local("Montserrat Bold"), local("Montserrat-Bold"),
        url("${basePath}/Montserrat/Montserrat-Bold.woff2") format("woff2");
        font-weight: bold;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: Montserrat;
        src: local("Montserrat Black"), local("Montserrat-Black"),
        url("${basePath}/Montserrat/Montserrat-Black.woff2") format("woff2");
        font-weight: 900;
        font-style: normal;
        font-display: swap;
      }


    `;
};
